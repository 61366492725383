<template>
  <div class="new-guide-card" v-on:click="()=>{}">
    <div class="new-guide-card__wrapper">
      <Column align="center"
              justify="center">
        <div class="new-guide-card__icon">
          <div class="new-guide-card__icon-line new-guide-card__icon-line-horizontal"></div>
          <div class="new-guide-card__icon-line new-guide-card__icon-line-vertical"></div>
        </div>
        <span class="new-guide-card__span">Добавьте нового<br/> гида</span>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewGuideCard',
};
</script>

<style lang="scss">
  @import "./src/assets/style/utilits/index";

  .new-guide-card {
    &__wrapper {
      @include size(350px, 355px);
      border: 1px solid $border;
      border-radius: 5px;
      @extend .extend-button;
    }

    .column {
      @include size(100%, 100%);
    }

    &__icon {
      @include position(relative);
      @include size(50px, 50px);
      margin: 0 0 20px;
      border: 3px solid $border;
      border-radius: 50%;

      &-line {
        background-color: $border;
        transform: translate(-50%, -50%);

        &-horizontal {
          @include position(absolute, $top: 50%, $left: 50%);
          @include size(27px, 3px);
        }

        &-vertical {
          @include position(absolute, $top: 50%, $left: 50%);
          @include size(3px, 27px);
        }
      }
    }

    &__span {
      @include size(235px);
      @include font(18px, bold, 1.67);
      color: rgba(0, 0, 0, .2);
      text-align: center;
    }
  }
</style>
